var assert = require('assert')

var dftOpts = {}
var hasWindow = typeof window !== 'undefined'
var hasIdle = hasWindow && window.requestIdleCallback

module.exports = onIdle

function onIdle (cb, opts) {
  opts = opts || dftOpts
  var timerId

  assert.equal(typeof cb, 'function', 'on-idle: cb should be type function')
  assert.equal(typeof opts, 'object', 'on-idle: opts should be type object')

  if (hasIdle) {
    timerId = window.requestIdleCallback(function (idleDeadline) {
      // reschedule if there's less than 1ms remaining on the tick
      // and a timer did not expire
      if (idleDeadline.timeRemaining() <= 1 && !idleDeadline.didTimeout) {
        return onIdle(cb, opts)
      } else {
        cb(idleDeadline)
      }
    }, opts)
    return window.cancelIdleCallback.bind(window, timerId)
  } else if (hasWindow) {
    timerId = setTimeout(cb, 0)
    return clearTimeout.bind(window, timerId)
  }
}
